/* around the white boxes */
.wrapper > * > :first-child {
  /* border: solid 1px red; */
}

/* around the entire grid item (yellow) */
.wrapper > :first-child {
  /* border: solid 1px red; */
}

/* around yellow header items */
.wrapper > :first-child > * > :first-child > * > * {
  /* border: solid 1px red; */
  display: flex;
  justify-content: center;
}

/* around the entire grid item (blue) */
.wrapper > :last-child {
  /* border: solid 1px blue; */
}

/* around blue header items */
.wrapper > :last-child > * > :first-child > * > * {
  /* border: solid 1px blue; */
  display: flex;
  justify-content: center;
}

.inner-grid-container {
  /* border: solid 1px green; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.grid1 {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: flex-start;
  width: 250px;
}

.icon1 {
  /* border: 2px solid orange; */
  display: flex;
  height: 50px;
  width: 50px;
}

.specialty-text {
  /* border: 2px solid greenyellow; */
  height: 100%;
  padding-left: 15px;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
}

#about-me {
  color: #26c6da !important;
}