.grid-images img {
  object-fit: scale-down;
  width:  550px;
  height: 550px;
  position: relative;
  border-radius: 0% 0% 0% 0% / 0% 0% 0% 0% ;
  color: white;
  box-shadow: 5px 5px 5px 5px rgba(0,0,0,.25);
  transition: all .4s ease;
}

.grid-images img:hover {
  border-radius: 0% 0% 50% 50% / 0% 0% 5% 5% ;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0,.25);
}

.grid-images-container {
  margin-top: 100px;
}

.grid-buttons {
  margin-top: 10px !important;
  margin-bottom: 25px !important;
}